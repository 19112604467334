<template>
  <div>
    <div class="mb-3">
      <TButton
        content="Create product"
        variant
        size
        color="info"
        :messageOptions="{ bold: true }"
        icon="cilPlus"
        to="affiliate-product/new"
        class="px-4"
      />
    </div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="product.product_affiliates"
      @click-clear-filter="clearFilter"
      resource="affiliate-product"
      enterable
      reloadable
      deletable
      @click-reload="fetch"
    >
      <template #product_id="{ item }">
        <td class="text-break">
          <TMessage :content="item.product_id" noTranslate :truncate="3" />
        </td>
      </template>
      <template #image_url="{ item }">
        <td>
          <SCardProductItems
            :item="{
              image_url: item.image_url,
              name: item.name,
              id: item.product_id,
            }"
            widthAuto
          />
        </td>
      </template>
      <template #price="{ item }">
        <td>
          <TMessageMoney
            :amount="item.price"
            :currency="item.currency_id || 'JPY'"
          />
        </td>
      </template>
      <template #initial_price="{ item }">
        <td>
          <TMessageMoney
            :amount="item.initial_price"
            :currency="item.currency_id || 'JPY'"
          />
        </td>
      </template>
      <template #delivery="{ item }">
        <td>
          <TMessage
            :content="
              item.delivery == 'free_delivery'
                ? 'Free delivery'
                : item.delivery == 'anonymous_delivery'
                ? 'Anonymous delivery'
                : 'Empty'
            "
          />
        </td>
      </template>
      <template #categories="{ item }">
        <td>
          <CBadge
            color="primary"
            v-for="cat in item.categories"
            :key="cat.id"
            class="mr-1"
          >
            {{ cat.name }}
          </CBadge>
          <TMessageNotFound v-if="!item.categories.length" />
        </td>
      </template>
      <template #affiliate_store_id="{ item }">
        <td>
          <TMessage
            :content="item.affiliate_store ? item.affiliate_store.name : ''"
            noTranslate
          />
        </td>
      </template>

      <template #url="{ item }">
        <td>
          <TLink :content="item.url" :to="item.url" />
        </td>
      </template>
      <template #product_id-filter>
        <TInputText
          :value.sync="filter.product_id"
          @update:value="filterChange"
          placeholder="Product Id"
        />
      </template>
      <template #image_url-filter>
        <TInputText
          :value.sync="filter.name"
          @update:value="filterChange"
          placeholder="Product name"
        />
      </template>
      <template #affiliate_store_id-filter>
        <SSelectAffiliateStore
          :value.sync="filter['affiliate_store_id']"
          @change="filterChange"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "product_id", label: "Product Id", _style: "max-width: 180px" },
        { key: "image_url", label: "Name", _style: "min-width: 350px" },
        { key: "price", label: "Price", _style: "min-width: 200px" },
        {
          key: "initial_price",
          label: "Initial price",
          _style: "min-width: 200px",
        },
        {
          key: "affiliate_store_id",
          label: "Affiliate store",
          _classes: "text-nowrap",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "categories",
          label: "Category",
          _classes: "text-nowrap",
          _style: "width: 200px; min-width: 200px",
        },
        { key: "delivery", label: "Delivery type", _style: "min-width: 200px" },
        { key: "url", label: "Link store", _style: "min-width: 200px" },
      ],
      filter: {},
      prepend: [{ id: "", name: this.$t("All") }],
    };
  },
  mounted() {
    this.$store.dispatch("product.product_affiliates.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "product.product_affiliates.list",
    }),
  },
  methods: {
    fetch() {
      this.$store.dispatch("product.product_affiliates.fetch");
    },
    filterChange() {
      const filter = this.lodash.mapKeys(
        { ...this.filter },
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
      });
      this.$store.dispatch(
        "product.product_affiliates.apply-query",
        filterFields
      );
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>
